<template>
  <div>
    <Header/>

    <loading
      :color="'#005BAC'"
      :active.sync="isSendingReq"
      :can-cancel="false"></loading>

    <section id="login">
      <div
        class="bg"
        :style="`background-image: url(${require('@/assets/img/account/img_2.jpg')});`">
      </div>
      <div class="bg2"></div>
      <div class="inner w1300">
          <!-- <div class="breadcrumbs">
              <span class="txt-lightgrey">首頁</span> > 註冊
          </div> -->

          <div class="login_box">
            <div class="title_box">
              <h4 class="txt-bold">
                註冊&nbsp;
                <span class="txt-style-i txt-highbold">Wi-Ho</span>&nbsp;
                <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
              </h4>
              <router-link
                :to="{ name: 'Login' }">
                  <p>
                    會員登入&nbsp;
                    <img src="@/assets/img/icon/icon_next.svg" alt="">
                  </p>
              </router-link>
            </div>

              <form action="">
                <div class="login_item">
                  <p class="txt-greenlake">手機號碼</p>
                  <input
                    type="text"
                    placeholder="請輸入手機號碼(必填)"
                    v-model="registerData.mobile"
                    maxlength="10"
                    required>
                </div>
                <div class="login_item">
                  <p class="txt-greenlake">Email</p>
                  <input
                    type="email"
                    placeholder="請輸入Email(必填)"
                    v-model="registerData.email"
                    required>
                </div>
                <div class="login_item">
                  <p class="txt-greenlake">密碼</p>
                  <input
                    type="password"
                    placeholder="請輸入英數混合8位數以上"
                    v-model="registerData.password"
                    @keyup="
                    registerData.password=registerData.password.replace(/[\u4e00-\u9fa5]/g,'')"
                    required>
                </div>
                <div class="login_item">
                  <p class="txt-greenlake">確認密碼</p>
                  <input
                    type="password"
                    placeholder="請再次輸入密碼"
                    v-model="checkPassword"
                    @keyup="
                    checkPassword=checkPassword.replace(/[\u4e00-\u9fa5]/g,'')"
                    required>
                </div>
                <div class="remember_box">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    value="1"
                    v-model="agreePrivacyPolicy"
                    required>
                  <label for="remember">
                    <span></span>
                    <p class="txt-grey">
                      我同意
                      <a
                        @click="toTerm();"
                        target="_blank" rel="noopener" class="txt-greenlake">
                        隱私權條款
                      </a>
                    </p>
                  </label>
                </div>
                <div class="btnbox txt-right">
                  <button
                    type="button"
                    @click="register(registerData)"
                    :disabled="isSendingReq"
                    class="btn btn-blue_blue_line">
                      <img src="@/assets/img/icon/icon_next-gl.svg" alt="">
                      <p>註冊</p>
                  </button>
                </div>
                <!-- <div class="openidbox">
                  <h6 class="p">或使用社群帳號註冊</h6>
                  <button type="button" class="btn btn-fb">
                    <img src="@/assets/img/icon/facebook-01.svg" alt="">
                    <p>Facebook</p>
                  </button>
                  <button type="button" class="btn btn-google">
                    <img src="@/assets/img/icon/google.png" alt="">
                    <p> Google</p>
                  </button>
                </div> -->
              </form>

          </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue';
import requestApi from '@/lib/http';
import '@/assets/scss/login.scss';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Register',
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      agreePrivacyPolicy: [],
      registerData: {
        email: '',
        password: '',
        mobile: '',
      },
      checkPassword: '',
      isSendingReq: false,
    };
  },
  methods: {
    toTerm() {
      const routeData = this.$router.resolve({ name: 'Terms', params: { type: 'Privacy' } });
      window.open(routeData.href, '_blank');
    },
    async register(params) {
      if (
        !this.registerData.mobile
        || !/^09[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{3}$/im.test(this.registerData.mobile)) {
        this.$customSWAL({
          icon: 'error',
          title: '請輸入正確的手機號碼',
        });
      } else if (
        !this.registerData.email
        || !/^[A-Za-z0-9._\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.registerData.email)) {
        this.$customSWAL({
          icon: 'error',
          title: '請填寫正確的Email信箱',
        });
      } else if (!this.registerData.password) {
        this.$customSWAL({
          icon: 'error',
          title: '請填寫密碼',
        });
      } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.registerData.password)) {
        this.$customSWAL({
          icon: 'error',
          title: '密碼須超過8個字元，包含英數字',
        });
      } else if (this.registerData.password !== this.checkPassword) {
        this.$customSWAL({
          icon: 'error',
          title: '密碼與確認密碼不符',
        });
      } else if (this.agreePrivacyPolicy[0] !== '1') {
        this.$customSWAL({
          icon: 'error',
          title: '請同意隱私權政策',
        });
      } else {
        this.isSendingReq = true;
        const { status, message } = await requestApi('member.register', params);
        this.isSendingReq = false;
        if (status) {
          this.$customSWAL({
            title: message,
            thenFunc: () => {
              this.$router.push({
                name: 'Login',
              });
            },
          });
        } else {
          this.$customSWAL({
            icon: 'error',
            title: message,
          });
        }
      }
    },
  },
};
</script>
